import React from 'react';
import { bool, string, func } from 'prop-types';
import { RecommendationsCarouselSnapped } from '@recommendations-fe/carousel-snapped';
import RecommendationsIconFactory from './icon-factory';
import { RecommendationsCardVertical } from './recomendations-card';

const Recommendations = ({
  isPolycard,
  thousandSeparator,
  decimalSeparator,
  runCatchErrorBoundary = () => {},
  ...props
}) => {
  try {
    return (
      <RecommendationsCarouselSnapped
        Card={RecommendationsCardVertical}
        IconFactory={RecommendationsIconFactory}
        contextProps={{
          type: 'grid-card',
          thousandSeparator,
          decimalSeparator,
        }}
        cardProps={{
          polyClassName: 'recos-polycard',
        }}
        isPolycard={isPolycard}
        {...props}
        className={`${props.className} recos-override__card-vertical`}
        runCatchErrorBoundary={runCatchErrorBoundary}
      />
    );
  } catch (error) {
    /* istanbul ignore next */
    return runCatchErrorBoundary(error);
  }
};

Recommendations.propTypes = {
  isPolycard: bool.isRequired,
  decimalSeparator: string.isRequired,
  thousandSeparator: string.isRequired,
  className: string,
  runCatchErrorBoundary: func,
};

export default Recommendations;
